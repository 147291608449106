// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-aguardando-pagamento-index-js": () => import("./../../../src/pages/aguardando-pagamento/index.js" /* webpackChunkName: "component---src-pages-aguardando-pagamento-index-js" */),
  "component---src-pages-confirme-seu-email-index-js": () => import("./../../../src/pages/confirme-seu-email/index.js" /* webpackChunkName: "component---src-pages-confirme-seu-email-index-js" */),
  "component---src-pages-curso-javascript-index-js": () => import("./../../../src/pages/curso-javascript/index.js" /* webpackChunkName: "component---src-pages-curso-javascript-index-js" */),
  "component---src-pages-dev-2021-components-header-index-js": () => import("./../../../src/pages/dev-2021/components/Header/index.js" /* webpackChunkName: "component---src-pages-dev-2021-components-header-index-js" */),
  "component---src-pages-dev-2021-components-schedule-index-js": () => import("./../../../src/pages/dev-2021/components/Schedule/index.js" /* webpackChunkName: "component---src-pages-dev-2021-components-schedule-index-js" */),
  "component---src-pages-dev-2021-day-1-index-js": () => import("./../../../src/pages/dev-2021/day-1/index.js" /* webpackChunkName: "component---src-pages-dev-2021-day-1-index-js" */),
  "component---src-pages-dev-2021-day-2-index-js": () => import("./../../../src/pages/dev-2021/day-2/index.js" /* webpackChunkName: "component---src-pages-dev-2021-day-2-index-js" */),
  "component---src-pages-dev-2021-day-3-index-js": () => import("./../../../src/pages/dev-2021/day-3/index.js" /* webpackChunkName: "component---src-pages-dev-2021-day-3-index-js" */),
  "component---src-pages-dev-2021-index-js": () => import("./../../../src/pages/dev-2021/index.js" /* webpackChunkName: "component---src-pages-dev-2021-index-js" */),
  "component---src-pages-es-6-index-js": () => import("./../../../src/pages/es6/index.js" /* webpackChunkName: "component---src-pages-es-6-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscricoes-abertas-index-js": () => import("./../../../src/pages/inscricoes-abertas/index.js" /* webpackChunkName: "component---src-pages-inscricoes-abertas-index-js" */),
  "component---src-pages-links-index-js": () => import("./../../../src/pages/links/index.js" /* webpackChunkName: "component---src-pages-links-index-js" */),
  "component---src-pages-maratona-js-3-dia-1-index-js": () => import("./../../../src/pages/maratona-js-3/dia-1/index.js" /* webpackChunkName: "component---src-pages-maratona-js-3-dia-1-index-js" */),
  "component---src-pages-maratona-js-3-dia-2-index-js": () => import("./../../../src/pages/maratona-js-3/dia-2/index.js" /* webpackChunkName: "component---src-pages-maratona-js-3-dia-2-index-js" */),
  "component---src-pages-maratona-js-3-dia-3-index-js": () => import("./../../../src/pages/maratona-js-3/dia-3/index.js" /* webpackChunkName: "component---src-pages-maratona-js-3-dia-3-index-js" */),
  "component---src-pages-maratona-js-3-index-js": () => import("./../../../src/pages/maratona-js-3/index.js" /* webpackChunkName: "component---src-pages-maratona-js-3-index-js" */),
  "component---src-pages-maratona-js-dia-1-index-js": () => import("./../../../src/pages/maratona-js/dia-1/index.js" /* webpackChunkName: "component---src-pages-maratona-js-dia-1-index-js" */),
  "component---src-pages-maratona-js-dia-2-index-js": () => import("./../../../src/pages/maratona-js/dia-2/index.js" /* webpackChunkName: "component---src-pages-maratona-js-dia-2-index-js" */),
  "component---src-pages-maratona-js-dia-3-index-js": () => import("./../../../src/pages/maratona-js/dia-3/index.js" /* webpackChunkName: "component---src-pages-maratona-js-dia-3-index-js" */),
  "component---src-pages-maratona-js-index-js": () => import("./../../../src/pages/maratona-js/index.js" /* webpackChunkName: "component---src-pages-maratona-js-index-js" */),
  "component---src-pages-obrigado-aluno-index-js": () => import("./../../../src/pages/obrigado-aluno/index.js" /* webpackChunkName: "component---src-pages-obrigado-aluno-index-js" */),
  "component---src-pages-obrigado-index-js": () => import("./../../../src/pages/obrigado/index.js" /* webpackChunkName: "component---src-pages-obrigado-index-js" */),
  "component---src-pages-pagamento-confirmado-index-js": () => import("./../../../src/pages/pagamento-confirmado/index.js" /* webpackChunkName: "component---src-pages-pagamento-confirmado-index-js" */),
  "component---src-pages-pre-inscricao-index-js": () => import("./../../../src/pages/pre-inscricao/index.js" /* webpackChunkName: "component---src-pages-pre-inscricao-index-js" */),
  "component---src-pages-promo-index-js": () => import("./../../../src/pages/promo/index.js" /* webpackChunkName: "component---src-pages-promo-index-js" */),
  "component---src-pages-react-index-js": () => import("./../../../src/pages/react/index.js" /* webpackChunkName: "component---src-pages-react-index-js" */),
  "component---src-pages-react-lista-espera-index-js": () => import("./../../../src/pages/react/lista-espera/index.js" /* webpackChunkName: "component---src-pages-react-lista-espera-index-js" */)
}

